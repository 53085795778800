import {useSeoContext} from "../../../context/seoContext/context";
import "./SeoPanelPage.css";

export const SearchPhaseRow = ({_id, id, websiteUrl, keywords})=>{
    const {deleteWebsiteKeyword,updateKeywordData} = useSeoContext()
    return <div>
        <div className={"productTitle activeProd"}> {websiteUrl} </div>
        <table>
            <thead>
            <tr className={"tableHeader"}>
                <th style={{width:"30%"}}>keyword</th>
                <th style={{width:"10%"}} title={"searches per day"}>🔎/☀️🌜</th>
                <th style={{width:"10%"}} title={"total searches"}>✅</th>
                <th style={{width:"10%"}}>Pos</th>

                <th style={{width:"10%"}}>Actions</th>
                <th></th>

            </tr>
            </thead>
            {keywords.map(k => <tr key={k._id}>
                <td>{k.keyword}</td>
                <td><input type={"number"} onChange={(e) => updateKeywordData(k._id, {searchesPerDay: Number(e.target.value)})}
                           value={k.searchesPerDay}/></td>
                <td>✅ {k.totalSearches}</td>
                <td><input  type={"number"}  onChange={(e) => updateKeywordData(k._id, {initialPosition: Number(e.target.value)})}
                           value={k.initialPosition}/></td>
                <td><span onDoubleClick={() => deleteWebsiteKeyword(k._id)}>❌</span></td>

            </tr>)}
        </table>

    </div>
}
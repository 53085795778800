import "./SeoPanelPage.css";
import {useEffect, useState} from "react";
import {usePsalmContext} from "../../../context/psalmContext/context";

import {SearchPhaseRow} from "./SearchPhaseRow";
import {useSeoContext} from "../../../context/seoContext/context";

const langs = [{"lang": "BG", "country": "Bulgaria"},
    {"lang": "EN", "country": "United Kingdom"},
    {"lang": "SI", "country": "Slovenia"},
    {"lang": "HR", "country": "Croatia"},
    {"lang": "IT", "country": "Italy"},
    {"lang": "CZ", "country": "Czech Republic"},
    {"lang": "SK", "country": "Slovakia"},
    {"lang": "HU", "country": "Hungary"},
    {"lang": "PT", "country": "Portugal"},
    {"lang": "ES", "country": "Spain"},
    {"lang": "PL", "country": "Poland"},
    {"lang": "DE", "country": "Germany"},
    {"lang": "AT", "country": "Austria"},
    {"lang": "CH", "country": "Switzerland"},
    {"lang": "LU", "country": "Luxembourg"},
    {"lang": "FR", "country": "France"},
    {"lang": "LT", "country": "Lithuania"},
    {"lang": "LV", "country": "Latvia"},
    {"lang": "EST", "country": "Estonia"},
    {"lang": "NL", "country": "The Netherlands"},
    {"lang": "BE", "country": "Belgium"},
    {"lang": "RO", "country": "Romania"},
    {"lang": "DK", "country": "Denmark"},
    {"lang": "SE", "country": "Sweden"},
    {"lang": "GR", "country": "Greece"}]
const SeoPanelPage = () => {

    const {seoWebsites, submitWebsiteKeywords, getWebsiteKeywords} = useSeoContext()
    const [brandName, setBrandName] = useState('bing')
    const [lang, setLang] = useState('')
    const [activeTab, setActiveTab] = useState('links')
    const [websiteUrl, setWebsiteUrl] = useState('')
    const [keywordsList, setKeywordsList] = useState('')

    useEffect(() => {

    }, [brandName]);
    useEffect(() => {
        getWebsiteKeywords({lang})
    }, [lang]);

    const handleSubmitKeywords = () => {
        let data = {websiteUrl, keywordsList: keywordsList.trim().split("\n").map(a=>a.trim()).filter(a=>a), lang}
        submitWebsiteKeywords(data)
    }

    return <div className={'panelView'}>
        <div className={'sidebar'}>
            <select defaultValue={brandName} className={'socBoxSelect'} onChange={(e) => setBrandName(e.target.value)}>
                <option value={"none"} disabled>Select Brand</option>
                <option value={'bing'} selected={true}>Bing.com</option>
                <option value={'google'} disabled={true}>Google.com</option>
            </select>
            <select defaultValue={lang} className={'socBoxSelect'} onChange={(e) => setLang(e.target.value)}>
                <option selected={true} value={''} disabled={true}>Select Language</option>
                {langs
                    .sort((a, b) => a.lang.localeCompare(b.lang))
                    .map(lg => <option value={lg.lang} key={lg.lang}>{lg.lang} - {lg.country}</option>)}
            </select>
            {lang && <div className={`socBoxBtn sidebarBtn ${activeTab === 'links' ? "active" : ""}`}
                          onClick={_ => setActiveTab('links')}>Manage links and keywords</div>}
            <h3>Total: {seoWebsites.reduce((acc,cur)=>acc+ cur.keywords.reduce((kwacc,kw)=>kwacc+kw.totalSearches,0),0)} searches made</h3>

        </div>
        <div className={'dataContainer'}>


            {lang && activeTab === 'links' && <div>
                <input className={'socBoxInput fullWidth'} placeholder={'website URL'} value={websiteUrl}
                       onChange={(e) => setWebsiteUrl(e.target.value)}/>

                <textarea className={'socBoxTextarea fullWidth'} rows={15} value={keywordsList}
                          style={{overflowX: "scroll", overflowWrap: 'normal', whiteSpace: "pre"}}
                          placeholder={'each keyword on a new line'}
                          onChange={(e) => setKeywordsList(e.target.value.replaceAll('"', "").replaceAll("'", ""))}></textarea>
                <div className={`socBoxBtn`} onClick={handleSubmitKeywords}>
                    Submit keywords
                </div>
                <br/>
                <div className={'productReviewsContainer'}>
                    {seoWebsites.filter(a=>a.keywords.length).map(ws => <SearchPhaseRow
                        key={`${ws._id}`} {...ws}/>)
                    }
                </div>
            </div>}

        </div>
    </div>;
};
export default SeoPanelPage;

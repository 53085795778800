import React, {useReducer, useContext, createContext} from 'react';

import reducer from './reducer';

import {
    INITIAL_LOAD_BEGIN,
    INITIAL_LOAD_SUCCESS,
    INITIAL_LOAD_ERROR,
} from './actions';
import axios from "../../config/axiosConfig";
import {STORE_PANEL_DATA} from "./actions";


const initialState = {
    seoLoading: true,
    errorMessage: '',
    seoWebsites: []
}

const SeoContext = createContext();

const SeoProvider = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const loadInitialData = () => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            //todo fetch


            //todo dispatch success

        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }
    }

    const submitWebsiteKeywords = async (data) => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            let resp = await axios.post('/submitWebsiteKeywords', data)

            dispatch({
                type: STORE_PANEL_DATA,
                payload: {seoWebsites: [resp.data, ...state.seoWebsites.filter(a => a._id !== resp.data._id)]}
            })
        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }

    }
    const updateKeywordData = async (keywordId, update) => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});
            dispatch({
                type: STORE_PANEL_DATA,
                payload: {
                    seoWebsites: state.seoWebsites.map(a => {
                        return {...a, keywords: a.keywords.map(a => a._id !== keywordId?a:{...a,...update})}
                    })
                }
            })
            let resp = await axios.post('/updateKeywordData', {keywordId, update})


        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }

    }
    const deleteWebsiteKeyword = async (keywordId) => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            let resp = await axios.post('/deleteWebsiteKeyword', {keywordId})
            if (resp.data.success) {
                dispatch({
                    type: STORE_PANEL_DATA,
                    payload: {
                        seoWebsites: state.seoWebsites.map(a => {
                            return {...a, keywords: a.keywords.filter(a => a._id !== keywordId)}
                        })
                    }
                })
            }
        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }

    }
    const getWebsiteKeywords = async (query = {}) => {
        try {
            dispatch({type: INITIAL_LOAD_BEGIN});

            let resp = await axios.get('/getWebsiteKeywords', {params: query})

            dispatch({
                type: STORE_PANEL_DATA,
                payload: {seoWebsites: resp.data}
            })
        } catch (e) {
            dispatch({
                type: INITIAL_LOAD_ERROR,
                payload: {
                    errorMessage: e.message
                }
            });
        }

    }


    return <SeoContext.Provider value={{
        ...state,
        loadInitialData,
        submitWebsiteKeywords,
        getWebsiteKeywords,
        deleteWebsiteKeyword,
        updateKeywordData
    }}>
        {children}
    </SeoContext.Provider>
}


const useSeoContext = () => {
    return useContext(SeoContext);
}

export {SeoProvider, initialState, useSeoContext}
import {BrowserRouter, Route, Routes} from "react-router-dom";
import IndexPage from "./components/pages/index/IndexPage";
import NavBar from "./components/partials/navbar/NavBar";
import LoginPage from "./components/pages/login/LoginPage";
import ControlPanelPage from "./components/pages/controlPanel/ControlPanelPage";
import CreateTasksPanelPage from "./components/pages/createTaskPanel/CreateTasksPanelPage";
import LinksPanelPage from "./components/pages/linksPanel/LinksPanelPage";
import ManualPanelPage from "./components/pages/manualPanel/ManualPanelPage";
import AccountDashboardPage from "./components/pages/accountDashboard/AccountDashboardPage";
import AgendashPage from "./components/pages/agendash/AgendashPage";
import ReviewsPanelPage from "./components/pages/reviewsPanel/ReviewsPanelPage";
import SeoPanelPage from "./components/pages/seoPanel/SeoPanelPage";

function App() {
    return (
        <BrowserRouter>
            <div className="app">
                <NavBar/>
                {/*App body  todo add route to backend const routeList!!!*/}
                <div className="app__body">
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={
                                <IndexPage/>
                            }
                        />
                        <Route
                            exact
                            path="/login"
                            element={
                                <LoginPage/>
                            }
                        />
                        <Route
                            exact
                            path="/controlPanel"
                            element={
                                <ControlPanelPage/>
                            }
                        />


                        <Route
                            exact
                            path="/createTasksPanel"
                            element={
                                <CreateTasksPanelPage/>
                            }
                        />
                        <Route
                            exact
                            path="/linksPanel"
                            element={
                                <LinksPanelPage/>
                            }
                        />
                        <Route
                            exact
                            path="/manualPanel"
                            element={
                                <ManualPanelPage/>
                            }
                        />
                        <Route
                            exact
                            path="/dashboard"
                            element={
                                <AccountDashboardPage/>
                            }
                        />
                        <Route
                            exact
                            path="/agendash"
                            element={
                                <AgendashPage/>
                            }
                        />
                        <Route
                            exact
                            path="/reviewsPanel"
                            element={
                                <ReviewsPanelPage/>
                            }
                        />

                        <Route
                            exact
                            path="/seoPanel"
                            element={
                                <SeoPanelPage/>
                            }
                        />


                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
